<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.MAIN_ASSETS.TRANSPORT.ALL') }}
              {{headerName}}
            </h3>
          </div>
        </div>

        <div class="card-body">
          <v-data-table
            :loading="tableLoading"
            :headers="headers"
            :items="truckList"
            hide-default-footer
            single-select
            @click:row="rowClick"
            item-key="id"
            loading-text="Ma'lumot yuklanmoqda"
            no-data-text="Malumot kiritilmagan"
            :page.sync="page"
            :items-per-page="10"
            class="row-pointer"
            @
          >
            <template v-slot:[`item.main_assets_name`]="{ item }">
              <div class="payment__name" :class="item.main_assets_color">
                <span
                  :class="[item.main_assets_label]"
                  class="label label-dot"
                ></span>
                {{ item.main_assets_name }}
              </div>
            </template>
            <template v-slot:[`item.status_name`]="{ item }">
              <div :class="item.status_color" class="status__name">
                {{ item.status_name }}
              </div>
            </template>

            <template v-slot:[`item.bought_date`]="{ item }">
              {{ item.bought_date | formatDate }}
            </template>
            <template v-slot:[`item.is_normal_truck_documents`]="{ item }">
              <div
                :class="item.is_normal_truck_documents_color"
                class="status__name"
              >
                {{ item.is_normal_truck_documents }}
              </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <Action :index="item" @reload:table="fetchTruckList" />
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="Math.ceil(truckList.length / 10)"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Action from '@/view/pages/transport/TruckList/Action.vue'

export default {
  data() {
    return {
      headerName: '',
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      page: 1,
      itemsPerPage: 10,
      items: [
        { id: 0, title: 'Barchasi', value: '' },
        { id: 1, title: 'Bo`sh', value: 'PUS' },
        { id: 2, title: 'Texnix Servisda', value: 'SER' },
        { id: 3, title: 'Buyurtmada', value: 'ORD' },
        { id: 4, title: 'Bronda', value: 'Bro' }
      ],
      modal: false,
      filter: false,
      truck_type__price_per_km: '',
      truck_type__model: '',
      truck_type__capacity_kg: '',
      truck_type__capacity_m3: '',
      truck_type__brand: '',
      is_normal_truck_documents: '',
      status: '',
      dvigatel_number: '',
      bought_date: '',
      kuzov_type: '',
      kuzov_number: '',
      reg_number__icontains: '',

      tableLoading: false
    }
  },

  components: {
    Action
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.MAIN_ASSETS.TITLE') },
      { title: this.$t('MENU.MAIN_ASSETS.TRANSPORT.TITLE') },
      { title: this.$t('MENU.MAIN_ASSETS.TRANSPORT.ALL') }
    ])

    this.fetchTruckList()
    this.$store.dispatch('getAllBrands')
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.BRAND'),
          value: 'truck_type.brand.name'
        },
        {
          text: this.$t('TABLE_HEADER.MODEL'),
          value: 'truck_type.model'
        },
        {
          text: 'Yuk, kg',
          value: 'truck_type.capacity_kg'
        },
        {
          text: 'Yuk, m3',
          value: 'truck_type.capacity_m3'
        },
        {
          text: this.$t('TABLE_HEADER.NUMBER'),
          value: 'reg_number'
        },
        {
          text: this.$t('TABLE_HEADER.BOUGHT_DATE'),
          value: 'bought_date'
        },
        {
          text: this.$t('TABLE_HEADER.MAIN_ASSETS'),
          value: 'main_assets_name'
        },
        {
          text: this.$t('TABLE_HEADER.DOCS'),
          value: 'is_normal_truck_documents'
        },
        {
          text: this.$t('TABLE_HEADER.STATUS'),
          value: 'status_name'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    allBrands() {
      const data = this.$store.state.requests.allBrands
      return data.reverse()
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    truckList() {
      const data = this.$store.state.requests.allTruckList
      let val = this.$route.query.key
      if (data) {
        return data.filter((x) => x.status == val)
      } else return data
    }
  },
  methods: {
    fetchTruckList() {
      this.tableLoading = true
      this.$store
        .dispatch('getTruckLists')
        .then(() => {
          this.tableLoading = false
        })
        .catch(() => {
          this.tableLoading = false
        })
    },

    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/truckdetails/' + item.id + '/')
        row.select(false)
      }
    },
    myFn() {
      // console.log(this.is_normal_truck_documents)
      const data = {}
      data.truck_type__model = this.truck_type__model
      data.reg_number__icontains = this.reg_number__icontains
      data.kuzov_number = this.kuzov_number
      data.kuzov_type = this.kuzov_type
      data.bought_date = this.bought_date
      data.dvigatel_number = this.dvigatel_number
      data.status = this.status
      data.is_normal_truck_documents = this.is_normal_truck_documents
      data.truck_type__brand = this.truck_type__brand
      data.truck_type__capacity_kg = this.truck_type__capacity_kg

      data.truck_type__capacity_m3 = this.truck_type__capacity_m3
      for (let i in data) {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!Boolean(data[i])) delete data[i]
      }
      if (
        !this.is_normal_truck_documents &&
        this.is_normal_truck_documents !== ''
      )
        data['is_normal_truck_documents'] = this.is_normal_truck_documents
      if (Object.keys(data).length) {
        this.$store.commit('setFilterData', {
          data: data,
          path: this.$route.path
        })
        this.$store.dispatch('truckListsSearch', data)
      } else this.$store.dispatch('getTruckLists', '1')
    },
    reset() {
      this.truck_type__model = ''
      this.reg_number__icontains = ''
      this.kuzov_number = ''
      this.kuzov_type = ''
      this.bought_date = ''
      this.dvigatel_number = ''
      this.status = ''
      this.is_normal_truck_documents = ''
      this.truck_type__brand = ''
      this.truck_type__capacity_m3 = ''
      this.$store.commit('setFilterData', {
        data: undefined,
        path: ''
      })
      if (this.$route.path !== '/trucklists') {
        this.$router.push('/trucklists')
      } else {
        this.$store.dispatch('getTruckLists', '1')
      }
    }
  }
}
</script>

<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 350px;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
