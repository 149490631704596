<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <router-link :to="'/truckdetails/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-file-1 text-success"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.DETAILS') }}</span>
        </router-link>
        <router-link :to="'/truckupdate/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </router-link>
        <a
          href="#"
          v-if="index.status == 'PUS' || index.status == 'SER'"
          @click="sendToService(index)"
          class="navi-link"
        >
          <span class="navi-icon">
            <i class="flaticon2-file-1 text-primary"></i>
          </span>
          <span class="navi-text">Texnik Servisga Yuborish\Olish</span>
        </a>
        <router-link :to="'/details/' + index.main_assets" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-file-1"></i>
          </span>
          <span class="navi-text">Asosiy Vositani Kurish</span>
        </router-link>
        <template>
          <div class="card__action">
            <v-dialog v-model="confirmModal" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <div class="navi-link w-100" v-bind="attrs" v-on="on">
                  <span class="navi-icon">
                    <i class="flaticon2-file-1 text-primary"></i>
                  </span>
                  <span class="navi-text">Odometrni yangilash</span>
                </div>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Odometrni yangilash</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          type="number"
                          dense
                          outlined
                          v-model.number="select.form.odometr"
                          label="Odometr"
                          @keypress="isNumberKey"
                        ></v-text-field>
                      </v-col>

                      <v-col>
                        <v-menu
                          v-model="sanaMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Sana"
                              :success="select.form.odometr_date !== ''"
                              readonly
                              :value="select.form.odometr_date | dateFilter"
                              dense
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="select.form.odometr_date"
                            @input="sanaMenu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <div class="text-center">
                          <v-btn text class="my-2" @click="confirmModal = false"
                            >Bekor Qilish</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            color="primary"
                            @click="saveOdometer"
                            >Saqlash</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </template>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss">
.dropdown-menu {
  z-index: 80;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import Swal from 'sweetalert2'
import { isNumberKey } from '@/utils'
import { mapActions } from 'vuex'
export default {
  name: 'DriverAction',
  props: {
    allUsers: Array,
    btnClass: String,
    index: Object,
    id: Number
  },
  methods: {
    isNumberKey,

    ...mapActions(['odometrUpdate']),

    sendToService(val) {
      console.log(val)
      Swal.fire({
        title: 'Tasdiqlang',
        text: "Ma'lumot tog'ri ekanligini tekshiring!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Xa, o'chirish",
        cancelButtonText: 'Bekor qilish'
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            status: val.status == 'PUS' ? 'SER' : 'PUS'
          }
          this.$store.dispatch('changeTruckStatus', {
            id: val.id,
            data: data
          })
        }
      })
    },

    saveOdometer() {
      if (Object.keys(this.select.form).some((key) => !this.select.form[key])) {
        Swal.fire({
          title: 'Xato',
          text: "Barcha parametrlarni to'ldirish shart",
          icon: 'warning'
        })
        return
      }

      try {
        this.odometrUpdate({
          truckId: this.index.id,
          data: this.select.form
        }).then(() => {
          Swal.fire({
            title: 'Saqlandi',
            text: 'Saqlash muvaffaqiyatli amalga oshirildi',
            icon: 'success'
          })

          this.$emit('reload:table')
        })
      } catch (error) {
        console.log(error)
        Swal.fire({
          title: 'Xato',
          text: "Ma'lumotlarni saqlashda muammo",
          icon: 'warning'
        })
      }
      this.confirmModal = false
    }
  },

  data() {
    return {
      confirmModal: false,
      select: {
        truckElement: {},
        form: { odometr: '', odometr_date: '' }
      },
      sanaMenu: false
    }
  }
}
</script>
